<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <v-card>
          <v-card-title class="elevation-1">
            Exclusive List
            <v-spacer></v-spacer>
            <v-col>
              <v-dialog
                :retain-focus="false"
                v-model="dialog"
                persistent
                max-width="600px"
                :key="dialog"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined color="green" v-bind="attrs" v-on="on">
                    Add Image
                  </v-btn>
                </template>
                <v-card>
                  <v-form v-model="addslider" ref="addcat">
                    <v-card-title>
                      <span class="headline">Add Exclusive</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <v-text-field
                              v-model="link"
                              label=" Link"
                              :rules="[rules.required]"
                              required
                              outlined
                            ></v-text-field>
                          </v-col>
                          <!-- <v-col cols="12" sm="12" md="12">
                            <v-checkbox
                              v-model="ishighlight"
                              label="Is Highlight"
                            ></v-checkbox>
                          </v-col> -->
                          <v-col cols="12" sm="12" md="12">
                            <ImageComp
                              :singleImage="editingitem.image"
                              :pageId="editingitem._id"
                              @stepper="winStepper"
                              :height="8"
                              :width="6"
                              :heading="'Upload Exclusive Image (600 * 800)'"
                              :componentType="'exclusiveImage'"
                            />
                          </v-col>
                          <v-col cols="12" sm="12" md="12">
                            <ImageComp
                              :singleImage="editingitem.appImage"
                              :pageId="editingitem._id"
                              @stepper="winStepper"
                              :height="300"
                              :width="220"
                              :heading="'Upload Exclusive App Image (220 * 300)'"
                              :componentType="'exclusiveAppImage'"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="dialogclose()">
                        Close
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        :disabled="!addslider"
                        @click="add()"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col>
              <v-dialog
                :retain-focus="false"
                v-model="dialogmain"
                persistent
                max-width="600px"
                :key="dialog"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined color="green" v-bind="attrs" v-on="on">
                    Add Highlight Image
                  </v-btn>
                </template>
                <v-card>
                  <v-form v-model="addslider" ref="addcat">
                    <v-card-title>
                      <span class="headline">Add Highlight Exclusive</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <v-text-field
                              v-model="link"
                              label=" Link"
                              :rules="[rules.required]"
                              required
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="12">
                            <v-checkbox
                              v-model="ishighlight"
                              label="Is Highlight"
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="12" sm="12" md="12">
                            <ImageComp
                              :singleImage="editingitem.image"
                              :pageId="editingitem._id"
                              @stepper="winStepper"
                              :height="790"
                              :width="770"
                              :heading="'Upload Exclusive Image (600 * 600)'"
                              :componentType="'exclusiveMainImage'"
                            />
                          </v-col>
                          <v-col cols="12" sm="12" md="12">
                            <ImageComp
                              :singleImage="editingitem.image"
                              :pageId="editingitem._id"
                              @stepper="winStepper"
                              :height="300"
                              :width="220"
                              :heading="'Upload Exclusive App Image (220 * 300)'"
                              :componentType="'exclusiveMainAppImage'"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="dialogclose1()">
                        Close
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        :disabled="!addslider"
                        @click="add()"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col>
              <v-dialog
                :retain-focus="false"
                v-model="dialog1"
                persistent
                max-width="600px"
                :key="dialog1"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined color="green" v-bind="attrs" v-on="on">
                    change Title
                  </v-btn>
                </template>
                <v-card>
                  <v-form v-model="addslider" ref="addslider">
                    <v-card-title>
                      <span class="headline">Change Exclusive Title</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="exclusivetitle"
                              label="Exclusive Title"
                              :rules="titleRule"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="exclusivesubtitle"
                              label="Exclusive Subtitle"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="12">
                            <v-textarea
                              outlined
                              v-model="exclusivecaption"
                              label="Exclusive Caption"
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="dialog1 = false"
                      >
                        Close
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        :disabled="!addslider"
                        @click="titleAdd()"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col>
              <v-btn outlined color="red" href="/exclusiveDeleted"
                >Deleted List</v-btn
              >
            </v-col>
          </v-card-title>
          <v-layout wrap pt-5 justify-center>
            <v-flex xs12 text-uppercase py-xs-4>
              <span style="font-size: 25px; color: #000000">{{
                title.title
              }}</span>
            </v-flex>
            <v-flex xs12 text-uppercase>
              <span style="font-size: 15px; color: #ff0000">{{
                title.subtitle
              }}</span>
            </v-flex>
            <v-flex xs12>
              <span style="font-size: 15px; color: #877e7e"
                >{{ title.caption }}
              </span>
            </v-flex>
          </v-layout>
          <div v-if="user.length > 0">
            <v-layout wrap pt-10 pb-10>
              <v-flex v-for="(item, i) in user" :key="i" md3 pa-4>
                <v-card style="line-height: 16px" tile flat>
                  <div v-viewer style="display: flex">
                    <img
                      style="width: 100%; object-fit: contain; cursor: pointer"
                      :src="baseURL + item.image"
                      height="300px"
                    />
                  </div>
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="#FF2323"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                  <v-layout wrap justify-center px-2>
                    <v-flex pt-2 xl12 md12 lg12 xs12 sm12>
                      <span style="font-size: 13px; color: #000000">
                        {{ item.link }} </span
                      ><br /><br />
                      <span
                        v-if="item.ishighlight == true"
                        style="
                          font-size: 13px;
                          font-weight: bold;
                          color: #000000;
                        "
                      >
                        Main Image
                      </span>
                      <span
                        v-else
                        style="
                          font-size: 13px;
                          font-weight: bold;
                          color: #000000;
                        "
                      >
                        Sub Image
                      </span>
                    </v-flex>
                    <v-flex pt-5 xl6 lg6 md6 pb-2 sm6 xs6 px-5 justify-center>
                      <v-dialog v-model="item.delete" max-width="600px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            small
                            class="mr-2"
                            outlined
                            block
                            v-on="on"
                            v-bind="attrs"
                            color="red"
                          >
                            Delete
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title
                            >Are you sure you want to delete this
                            Exclusive?</v-card-title
                          >
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="item.delete = false"
                              >Cancel</v-btn
                            >
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="deleteItem(item)"
                              >OK</v-btn
                            >
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-flex>
                    <v-flex pt-5 xl6 lg6 md6 pb-2 sm6 xs6 px-5 justify-center>
                      <v-btn
                        small
                        class="mr-2"
                        outlined
                        block
                        color="green"
                        @click="editSlider(item)"
                      >
                        Edit
                      </v-btn>
                    </v-flex>
                    <br />
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </div>
          <div v-else>
            <v-layout pt-15 justify-center fill-height wrap>
              <v-flex text-center xs12 lg12>
                <span style="font-size: 22px; letter-spacing: 1px">
                  No Data Found...
                  <br />
                  Try adding Data.
                </span>
              </v-flex>
            </v-layout>
          </div>
          <v-dialog v-model="editdialog" max-width="600px">
            <v-card>
              <v-card-title>
                <span class="headline">Edit Exclusive</span>
              </v-card-title>
              <v-card-text>
                <v-layout>
                  <v-flex xs12 md12 lg12>
                    <v-text-field
                      v-model="editingitem.link"
                      label=" Link"
                      :rules="[rules.required]"
                      required
                      outlined
                    >
                    </v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs12 md12 lg12>
                    <v-checkbox
                      v-model="editingitem.ishighlight"
                      label="Display In Top Side"
                    ></v-checkbox>
                  </v-flex>
                </v-layout>
                <v-layout wrap>
                  <v-flex
                    xs12
                    md12
                    lg12
                    v-if="editingitem.ishighlight == false"
                  >
                    <ImageComp
                      :singleImage="editingitem.image"
                      :pageId="editingitem._id"
                      @stepper="winStepper"
                      :height="8"
                      :width="6"
                      :heading="'Upload Exclusive Image (600 * 800)'"
                      :componentType="'exclusiveImage'"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    md12
                    lg12
                    v-if="editingitem.ishighlight == false"
                  >
                    <ImageComp
                      :singleImage="editingitem.appImage"
                      :pageId="editingitem._id"
                      @stepper="winStepper"
                      :height="300"
                      :width="220"
                      :heading="'Upload Exclusive App Image (220 * 300)'"
                      :componentType="'exclusiveAppImage'"
                    />
                  </v-flex>
                  <v-flex v-else xs12 md12 lg12>
                    <ImageComp
                      :singleImage="editingitem.image"
                      :pageId="editingitem._id"
                      @stepper="winStepper"
                      :height="790"
                      :width="770"
                      :heading="'Upload Exclusive Image (600 * 600)'"
                      :componentType="'exclusiveMainImage'"
                    />
                    <!-- :height="790"
                      :width="770" -->
                  </v-flex>
                  <v-flex v-if="editingitem.ishighlight == true" xs12 md12 lg12>
                    <ImageComp
                      :singleImage="editingitem.appImage"
                      :pageId="editingitem._id"
                      @stepper="winStepper"
                      :height="300"
                      :width="220"
                      :heading="'Upload Exclusive App Image (220 * 300)'"
                      :componentType="'exclusiveMainAppImage'"
                    />
                  </v-flex>
                </v-layout>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="editdialog = false">
                  Close
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="edit(editingitem._id)"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import ImageComp from "@/components/commonComponents/singleImage";
export default {
  components: {
    ImageComp,
  },
  data() {
    return {
      ishighlight: false,
      ServerError: false,
      file: null,
      exclusiveImage: null,
      exclusiveAppImage: null,
      exclusiveMainImage: null,
      exclusiveMainAppImage: null,
      showsnackbar: false,
      editingitem: [],
      msg: null,
      pages: 0,
      link: "",
      widthOfCard: "250px",
      appLoading: false,
      currentPage: 1,
      totalData: 0,
      totalRows: 0,
      dialogDelete: false,
      dialog1: false,
      dialogmain: false,
      exclusivetitle: null,
      exclusivesubtitle: null,
      exclusivecaption: null,
      search: "",
      ratings: [],
      dialog: false,
      editdialog: false,
      user: [],
      title: [],
      addslider: false,
      imgId: "",
      image: {
        size: "",
        height: "",
        width: "",
      },
      imageError: "",
      rules: {
        required: (value) =>
          /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/.test(
            value
          ) || "Must be link",
        min: (v) => v.length >= 10 || "Min 10 characters",
      },
      Rules: [(value) => !!value || "Required."],
      titleRule: [(v) => v.length < 50 || "Min 50 characters"],
    };
  },
  mounted() {
    this.getData();
    this.getTitle();
  },
  methods: {
    winStepper(window_data) {
      if (window_data.type == "exclusiveImage") {
        this.exclusiveImage = window_data.selectedFiles;
      }
      if (window_data.type == "exclusiveMainImage") {
        this.exclusiveMainImage = window_data.selectedFiles;
      }
      if (window_data.type == "exclusiveMainAppImage") {
        this.exclusiveMainAppImage = window_data.selectedFiles;
      }
      if (window_data.type == "exclusiveAppImage") {
        this.exclusiveAppImage = window_data.selectedFiles;
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/home/exclusive/getlist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          count: this.count,
          page: this.currentPage,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.user = response.data.data;
          this.pages = response.data.pages;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getTitle() {
      this.appLoading = true;
      axios({
        url: "/home/titles/get",

        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.title = response.data.data.exclusive;
          this.exclusivetitle = this.title.title;
          this.exclusivesubtitle = this.title.subtitle;
          this.exclusivecaption = this.title.caption;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    add() {
      var data = {};
      data["link"] = this.link;
      data["ishighlight"] = this.ishighlight;
      axios({
        url: "/home/exclusive/add",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.imgId = response.data.id;
            this.uploadImage(response.data.id);
            this.uploadAppImage(response.data.id);
            this.getData();
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            this.link = null;
            this.checkbox = null;
            this.exclusiveMainImage = null;
            this.exclusiveImage = null;
            this.dialog = false;
            this.dialogmain = false;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    titleAdd() {
      var data = {};
      data["exclusivetitle"] = this.exclusivetitle;
      data["exclusivesubtitle"] = this.exclusivesubtitle;
      data["exclusivecaption"] = this.exclusivecaption;
      axios({
        url: "/home/titles/update",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            this.exclusivetitle = null;
            this.exclusivesubtitle = null;
            this.exclusivecaption = null;
            this.dialog1 = false;
            this.getTitle();
            // this.$router.push("/home/exclusiveList");
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deleteItem(r) {
      var data = {};
      data["id"] = r._id;
      axios({
        url: "/home/exclusive/remove",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.link = null;
      this.dialog = false;
      this.exclusiveMainImage = null;
      this.exclusiveImage = null;
    },
    dialogclose1() {
      this.link = null;
      this.dialogmain = false;
      this.exclusiveMainImage = null;
      this.exclusiveImage = null;
    },
    uploadImage(id) {
      let formData = new FormData();
      formData.append("id", id);
      formData.append("photo", this.exclusiveImage);
      formData.append("photo", this.exclusiveMainImage);

      axios({
        method: "POST",
        url: "/home/exclusive/uploadimage",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.getData();
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            this.dialog = false;
            this.dialogmain = false;
            this.exclusiveMainImage = null;
            this.exclusiveImage = null;
          } else {
            this.msg = "Can't Update";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    uploadAppImage(id) {
      let formData = new FormData();
      formData.append("id", id);
      formData.append("photo", this.exclusiveMainAppImage);
      formData.append("photo", this.exclusiveAppImage);

      axios({
        method: "POST",
        url: "/home/exclusive/uploadappimage",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.getData();
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            this.dialog = false;
            this.dialogmain = false;
            this.exclusiveMainImage = null;
            this.exclusiveImage = null;
          } else {
            this.msg = "Can't Update";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    edit() {
      this.appLoading = true;
      var user = {};
      user["link"] = this.editingitem.link;
      user["ishighlight"] = this.editingitem.ishighlight;
      user["id"] = this.editingitem;
      axios({
        method: "POST",
        url: "/home/exclusive/edit",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: user,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.editdialog = false;
            if (this.exclusiveImage) {
              this.uploadImage(this.editingitem._id);
            }
            if (this.exclusiveMainImage) {
              this.uploadImage(this.editingitem._id);
            }
            if (this.exclusiveMainAppImage) {
              this.uploadAppImage(this.editingitem._id);
            }
            if (this.exclusiveAppImage) {
              this.uploadAppImage(this.editingitem._id);
            } else {
              this.getData();
              this.$router.push("/home/exclusiveList");
            }
          } else {
            this.msg = "Can't Update";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editSlider(item) {
      this.editingitem = item;
      this.editdialog = true;
    },
  },
};
</script>